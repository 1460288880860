.accordion {
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    font-size: 16px;

    &__header {
        background-color: #919191;
        border: none;
        margin-bottom: 8px;
        color: #fff;
        padding: 10px 20px;
        background-color: #919191;
        border-radius: 0px;
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 0.25rem;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: rgb(229, 57, 53);
        }

        .icon {
            margin-right: 16px;
        }
    }

    &__content {
        padding: 1.5rem; 
    }
}