.home-page__info-card {
    font-size: 49px;
    white-space: pre;

    div {
        opacity: 0;
        animation: fadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    button {
        opacity: 0;
        animation: fadeInUp;
        animation-duration: 1s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
}