@import "../../SharedStyles/variables.scss";

.unbeatable-section {
    background-image: url(../../Assets/Images/ba_2.jpeg);
    background-size: cover;
    padding: 100px 10%;
    color: #ffffff;
    line-height: 28px;

    display: grid;
    grid-template-columns: 50% 50%;
    gap: 24px;

    div.info {
        margin: 15px 0;

        h2 {
            font-size: 27px;
            margin-bottom: 8px;
        }

        font-weight: 600;
    }

    div.service-cards {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        gap: 32px;
    }
}

@media (max-width: $breakpoint-tablet) {
    .unbeatable-section {
        grid-template-columns: 100%;
    }
}

@media (max-width: $breakpoint-phone) {
    .unbeatable-section {
        div.service-cards {
            flex-direction: column;
        }
    }
}