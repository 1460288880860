.admin-dashboard {
    font-size: 16px;

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;

        .data-table {
            margin-top: 5%;
            width: 100%;
            color: #fff;
            background-color: #343a40;
            border-collapse: collapse;
            text-indent: initial;
            border-spacing: 2px;
            border-color: #454d55;

            button {
                cursor: pointer;
                font-size: 0.875rem;
                line-height: 1;
                font-weight: 600;
                border-radius: 0.25rem;
                color: #fff;
                border: 1px solid transparent;
                padding: 0.625rem 1.125rem;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                text-align: center;
                vertical-align: middle;
                user-select: none;
                display: inline-block;
                outline: none;
            }

            button.update {
                background-color: #464dee;
                border-color: #464dee;

                &:hover {
                    background-color: #232beb;
                    border-color: #1720ea;
                }
            }

            button.delete {
                background-color: #ee5b5b;
                border-color: #ee5b5b;

                &:hover {
                    background-color: #ea3838;
                    border-color: #e92d2d;
                }
            }

            th,td {
                line-height: 1;
                padding: 1.25rem 0.9375rem;
                border-color: #454d55;
            }

            th {
                text-align: inherit;
            }

            tr {
                vertical-align: inherit;
                border-color: inherit;
            }

            td {
                font-size: 1rem;
                font-weight: 500;
            }

            thead {
                display: table-header-group;
                vertical-align: middle;
                border-color: inherit;

                th {
                    border-top: 0;
                    border-bottom-width: 1px;
                    font-weight: 400;
                    font-size: 1rem;
                    vertical-align: bottom;
                    border-bottom: 1px solid #454d55;
                }
            }

            tbody {
                vertical-align: middle;
                border-color: inherit;
            }
        }
    }
}