@import "../../SharedStyles/variables.scss";

.layout {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &__menu {
        padding: 8px 10%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: #fff;
        z-index: 2;

        &__solid {
            background-color: #363535;
        }
        // font-family: "Poppins";

        &__socials {
            border-bottom: 0.5px solid #ffffff;
            font-size: 12px;
            font-weight: 300;
            padding-bottom: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__info {
                flex: 0.6;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                span {
                    .icon {
                        margin-right: 16px;
                    }
                }
            }

            &__links {
                flex: 0.4;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 12px;

                div {
                    cursor: pointer;
                }
            }
        }

        &__content {
            padding-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            gap: 16px;

            img {
                width: 30%;
                height: 50px;
                object-fit: cover;
            }

            button.get-quote {
                cursor: pointer;
                border: none;
                outline: none;
                margin-left: auto;
                padding: 8px 20px;
                border-radius: 30px;
                background-color: #e53935;
                color: #fff;
                font-weight: 400;
                font-size: 16px;

                .icon {
                    margin-right: 8px;
                }
            }
        }
    }

    &__menu-mobile {
        display: none;
    }

    &__footer {
        width: 100%;
        background-color: #4a4a4a;
        color: #ffffff;
        font-weight: 600;
        line-height: 24px;
        line-height: 28px;

        &__light {
            padding: 100px 10%;

            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            .read-more {
                transition: background-color 300ms;
                border: none;
                margin-top: 16px;
                &:hover {
                    background-color: #868e96;
                }
            }

            div.footer__section {
                flex: 1 0 calc(25% - 50px);
                display: inline-block;
                padding-left: 16px;

                h5 {
                    font-size: large;
                    margin-bottom: 24px;
                }

                .location, .call-center {
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    line-height: 20px;
                    align-items: center;

                    div {
                        span {
                            display: block;

                            &:nth-child(1) {
                                opacity: .8;
                            }
                        }
                    }

                    .icon {
                        font-size: 32px;
                        margin-left: -12px;
                    }
                }

                &:nth-child(1) {
                    padding-left: 0;
                }

                @media (max-width: $breakpoint-tablet) {
                    flex: 1 0 calc(50% - 50px);
                }

                @media (max-width: $breakpoint-phone) {
                    flex: 1 0 calc(100% - 50px);
                }
            }

            img {
                width: 100%;
                height: 50px;
                object-fit: cover;
            }

            @media (max-width: $breakpoint-tablet) {
                gap: 64px;
            }
        }

        &__darker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 50px 10%;
            background-color: #363535;

            ul {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: 16px;

                svg {
                    cursor: pointer;
                }
            }

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
                text-align: center;
                gap: 16px;
            }
        }
    }

    @media (max-width: $breakpoint-laptop-sm) {
        &__menu__content {
            img {
                height: unset;
                width: 200px;
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        &__menu {
            display: none;
        }
        
        &__menu-mobile {
            display: block;
            padding: 16px 10%;
            background-color: #E53934;
            color: white;

            &__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 16px;

                img {
                    width: 70%;
                    object-fit: contain;
                }
            
                .hambug {
                    background-color: #3F3E3E;
                    padding: 16px;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }

            &__container {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-top: 32px;
            }

            .services {
                &__top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                &__options {
                    background-color: #212121;
                    border-radius: 10px;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                }
            }

            &__item {
                padding-bottom: 12px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}