@import '../../../SharedStyles/variables.scss';

.admin-settings {
    width: 50%;
    margin: 0 auto;

    h3 {
        display: inline-block;
        margin-bottom: 24px;
    }

    input {
        border: 1px solid #f1f6f8;
        font-weight: normal;
        font-size: 0.875rem;
        height: auto;
        width: 100%;
        outline: none;
        display: block;
        background-clip: padding-box;
        padding: 0.875rem 1.375rem;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1;
        color: #495057;

        &::placeholder {
            color: #b7bec0;
        }

        &:focus {
            border-color: #cd3734;
        }
    }

    .input-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .input-group {
            label {
                display: inline-block;
                font-size: 1rem;
                line-height: 1.4rem;
                color: #d68d8c;
                vertical-align: top;
                margin-bottom: 0.5rem;
            }
        }
    }

    button.save {
        width: 100%;
        border-radius: 6px;

        &:hover {
            background-color: #cd3734;
            border-color: #cd3734;
        }
    }

    @media (max-width: $breakpoint-phone) {
        width: 100%;
    }
}