.custom-alert {
    word-wrap: break-word;
    margin-bottom: 8px;

    &__alert {
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 12px 8px;
    }

    &__danger {
        color: #7c2f2f;
        background-color: #fcdede;
        border-color: #fad1d1;
    }

    &__success {
        color: #198343;
        background-color: #A8F1C6;
        border-color: #6ad797;
    }
}