.footer-link {
    cursor: pointer;   
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid rgba(238, 238, 238, 0.1);

    .icon {
        margin-right: 12px;
        margin-left: -12px;
        font-size: 8px;
        display: inline-block;
    }

    span {
        color: #b1b1b1;
        display: inline-block;
    }
}