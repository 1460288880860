@import "../../SharedStyles/variables.scss";

.contact-us {
    &__top {
        background-image: url(../../Assets/Images/about-us-bg.jpeg);
        padding: 0 10%;
        padding-top: 160px;
        padding-bottom: 64px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: grey;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: ease 1s;
        text-align: center;
    }

    &__content {
        line-height: 28px;

        &__details {
            padding: 100px 10%;
            background-color: #fff;

            &__content {
                width: 50%;
                font-size: 16px;

                .label {
                    margin-top: 16px;
                    font-weight: 600;
                    display: block;
                }

                .value {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;

                    .icon {
                        font-size: 14px;
                        color: #e53935;
                    }
                }

                @media (max-width: $breakpoint-tablet) {
                    width: 100%;
                }
            }
        }
    }
}