div.track-it {
    &__top {
        background-image: url(../../Assets/Images/about-us-bg.jpeg);
        padding: 0 10%;
        padding-top: 160px;
        padding-bottom: 64px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: grey;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: ease 1s;
        text-align: center;
    }

    &__content {
        padding: 100px 10%;   
    }
}