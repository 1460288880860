.app-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #fff, $alpha: 0.85);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-overlay-enter {
    opacity: 0;
}

.app-overlay-enter-active {
    animation: fadeIn 300ms forwards;
}

.app-overlay-exit {
    opacity: 1;
}

.app-overlay-exit-active {
    animation: fadeOut 300ms forwards;
}