.page-card {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
    border-radius: 6px;
    word-wrap: break-word;

    &__body {
        padding: 1.875rem 1.875rem;
    }
}