@import '../../../SharedStyles/variables.scss';

.admin-add-tracking {
    .custom-alert {
        margin-bottom: -24px;
    }

    h3 {
        color: #b41d1a;
    }

    input, textarea, select {
        border: 1px solid #f1f6f8;
        font-weight: normal;
        font-size: 0.875rem;
        height: auto;
        width: 100%;
        outline: none;
        display: block;
        background-clip: padding-box;
        padding: 0.875rem 1.375rem;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1;
        color: #495057;

        &::placeholder {
            color: #b7bec0;
        }

        
    }

    input,
    textarea {
        &:focus {
            border-color: #cd3734;
        }

        &:read-only {
            background-color: #e9ecef;
            opacity: 1;
        }
    }

    input.tracking_number {
        margin-top: 16px;
    }

    form.tracking-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 32px;
        margin-top: 3%;

        .sender, .receiver {
            flex: 1;

            h4 {
                margin-bottom: 24px;
            }

            .input-container {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .input-group {
                    label {
                        display: inline-block;
                        font-size: 1rem;
                        line-height: 1.4rem;
                        color: #d68d8c;
                        vertical-align: top;
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }

    .tracking-action {
        margin-top: 3%;

        button {
            border-radius: 6px;
            width: 100%;

            &:hover {
                background-color: #cd3734;
                border-color: #cd3734;
            }
        }
    }

    @media (max-width: $breakpoint-phone) {
        form.tracking-info {
            flex-direction: column;
            gap: 24px;
        }
    }
}