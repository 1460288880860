@import "variables.scss";

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./Assets/Fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/Fonts/Poppins-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./Assets/Fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./Assets/Fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

.App {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  transition: ease 0.3s;
}