.custom-input-area {
    .label {
        margin-bottom: 8px;
    }

    font-size: 16px;
    font-weight: 500;

    textarea {
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
        color: #fff;
        outline: none;
        background-color: transparent;
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        padding: 6px 12px;

        &::placeholder {
            color: #fff;
        }
    }
}