.nav-item {
    font-size: 16px;
    font-weight: 500;
    position: relative;

    .icon {
        font-size: 14px;
    }

    span {
        opacity: 0.8;
        transition: 0.4s;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}

.nav-item-menu {
    position: absolute;
    top: 18px;
    left: 0;
    padding-top: 32px;
    background-color: transparent;

    &__container {
        background-color: #212121;
        width: 230px;
        border-radius: 10px;
        padding: 16px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .option {
            opacity: .8;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 1;
                transform: translateX(8px);
            }
        }
    }

    &-enter {
        transform: translateY(-24px);
        opacity: 0;

        &-active {
            transform: translateY(0);
            animation: fadeIn 0.3s forwards;
            transition: transform 0.3s ease-in;
        }
    }

    &-exit {
        transform: translateY(0);
        opacity: 1;

        &-active {
            transform: translateY(-24px);
            animation: fadeOut 0.3s forwards;
            transition: transform 0.3s ease-out;
        }
    }
}