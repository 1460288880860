@import "../../SharedStyles/variables.scss";

.about-us {
    &__top {
        background-image: url(../../Assets/Images/about-us-bg.jpeg);
        padding: 0 10%;
        padding-top: 160px;
        padding-bottom: 64px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: grey;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: ease 1s;
        text-align: center;
    }

    &__content {
        section {
            &:nth-child(1) {
                padding: 100px 10%;
                background-image: url(../../Assets/Images/ba_1.jpg);
                background-size: cover;
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 24px;

                div.section1-col {
                    &:nth-child(1) {
                        h4 {
                            margin: 16px 0;
                            font-size: 18px;
                        }
                        
                        p {
                            font-size: 16px;
                            word-spacing: 2px;
                            line-height: 28px;
                        }
                    
                        .title-text {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                    
                            img {
                                margin-right: 16px;
                                display: inline-block;
                            }
                    
                            font-size: 26px;
                            line-height: 29px;
                            font-weight: 600;
                            color: #e53935;
                        }
                    }

                    &:nth-child(2) {
                        img {
                            max-width: 100%;
                        }
                    }
                }

                @media (max-width: $breakpoint-tablet) {
                    grid-template-columns: 100%;
                    
                    div.section1-col {
                        &:nth-child(2) {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                padding: 100px 10%;
                background-image: url(../../Assets/Images/ba_1.jpg);
                background-size: cover;
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between;
                gap: 24px;

                .testimonial {
                    background-color: #fff;
                    padding: 50px;
                    text-align: center;
                    box-shadow: 0px 0px 36px -13px rgb(0 0 0 / 8%);

                    h4 {
                        font-size: 18px;
                        margin: 24px 0;
                    }

                    p {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }

                @media (max-width: $breakpoint-phone) {
                    flex-direction: column;
                }
            }
        }
    }
}