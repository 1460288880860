.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;

    .slideshowSlider {
        white-space: nowrap;
        transition: ease 1000ms;

        div.slide {
            display: inline-block;
            width: 100%;
        }

        img.slide {
            width: 100%;
            // height: 500px;
            object-fit: contain;
        }
    }

        .slideshowDots {
            text-align: center;

            .slideshowDot {
                display: inline-block;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                cursor: pointer;
                margin: 10px 10px 10px 0; 
                background-color: #fff;
                border: 1px solid #e53935;
                opacity: .5;

                &-active,
                &:hover {
                    opacity: 1;
                }
            }
        }
}